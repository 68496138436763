import React, { useEffect, useState } from "react";
import Fliesstext from "../components/Text/Fliesstext";
import DefaultLayout from "../layouts/default";
import Wrapper from "../components/Wrappers/Wrapper";

const TextSeite: React.FC = () => {
  const [siteId, setSiteId] = useState(0);
  useEffect(() => {
    if (typeof window != "undefined") {
      const href = window.location.href;
      let newSiteId = 1;
      href.includes("evang-pflegeheime") || href.includes("evangpfl")
        ? (newSiteId = 1)
        : href.includes("betreutes-wohnen-heiligkreuz") ||
          href.includes("betreuth")
        ? (newSiteId = 2)
        : href.includes("pflegeheim-bruggen") || href.includes("pflegehb")
        ? (newSiteId = 3)
        : href.includes("pflegeheim-heiligkreuz") || href.includes("pflegehh")
        ? (newSiteId = 4)
        : (newSiteId = 1);
      setSiteId(newSiteId);
    }
  }, []);

  return (
    <>
      {siteId == 0 ? (
        ""
      ) : (
        <DefaultLayout siteId={siteId} description={"404 Seite"} title={"404"}>
          <Wrapper>
            <Fliesstext
              text={
                "<br /><br /<br /><br /<br /><br /><h4>404</h4><br />Leider nichts gefunden.<br /><a href='/'>zurück zur Startseite</a><br /><br /><br />"
              }
              siteId={siteId}
            />
          </Wrapper>
        </DefaultLayout>
      )}
    </>
  );
};

export default TextSeite;
